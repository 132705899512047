<template>
  <div class="order-list">
     <div class="filter-container">
      <div class="brand-filter">
        <span>商家选择:</span>
        <Dropdown style="margin-left: 20px" @on-click="onBrandItemClick">
          <Button type="primary">
            <span>{{ brandName }}</span>
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem
              :name="item.id"
              :selected="brandType == item.id"
              v-for="(item, index) in brandList"
              :key="index"
              >{{ item.name }}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>

    <Table :columns="ordersColumns" :data="ordersData" border :max-height="tableMaxHeight">
    </Table>

     <div
      style="margin: 20px 12px;"
    >
      <div style="display:flex;flex-direction:row-reverse;">
        <Page
          :total="totalCount"
          :current="currentPage"
          @on-change="changePage"
          :page-size="pageSize"
          show-total
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinDevice } from '@/util/mixin';
import api from '@/api';

export default {
  mixins:[mixinDevice],
  data(){
    return {
      brandType: null,
      brandList: [],
      totalCount: 0,
      currentPage: 1,
      pageSize: 30,
      ordersColumns:[
       {
          title: "订单号",
          key: "order_no",
        },
        {
          title: '创建时间',
          key: 'create_time',
          default: '---'
        },
        {
          title: '激活后有效时间',
          key: 'effect_time',
          default: '---'
        }
      ],
      ordersData:[]
    }
  },
  methods:{
    changePage(value){
      this.currentPage = value;
      this.loadData();
    },
    onBrandItemClick(name) {
      this.$log.debug("name: ", name);
      if(name != this.brandType){
        this.brandType = name;
        this.currentPage = 1;
        this.loadData();
      }
    },
    loadData(){
      api.branchOrderList(this.brandType,this.currentPage,this.pageSize).then(res=>{
        this.$log.debug(res);
        if(res.orders && res.orders.length > 0){
          this.ordersData = res.orders.map(item=>{
            var newItem = item;
            if(!newItem.create_time || newItem.create_time === ''){
              newItem.create_time = '/'
            }
            if(!newItem.effect_time || newItem.effect_time === ''){
              newItem.effect_time = '/'
            }
            return newItem;
          })
        }else{
          this.ordersData = [];
        }
        this.totalCount = res.total_count;
      }).catch(err=>{
        this.$log.debug(err);
      });
    },
    loadMerchants(){
      api.merchantList().then(res=>{
        this.brandList = res.merchants;
        if(this.brandList){
          this.brandType = this.brandList[0].id;
        }
        this.loadData();  
      }).catch(err=>{
        this.$Message.error("拉取商家数据失败: "+err.message);
      })
    }
  },
  computed:{
     tableMaxHeight(){
      return this.isMobile() ? 540 : 700;
    },
    brandName(){
      if(!this.brandType) return '';
      return this.brandList.filter(item=>{
        return item.id == this.brandType;
      })[0].name;
    }
  },
  mounted(){
    this.loadMerchants();
  }
}
</script>

<style lang="less" scoped>
  .order-list{
    width: 100%;

    .filter-container {
      margin-bottom: 12px;
      padding: 12px 24px;
      border: 1px dashed green;
      display: flex;

      .search-container {
        margin-left: auto;
      }

      @media screen and (max-width: 750px) {
        
        flex-direction: column;
      
        .search-container {
          margin-left: 0;
          margin-top: 10px;
        }
      }
  }
  }
</style>

